@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'satoshi-medium';
    src: url('./assets/fonts/Satoshi-Medium.woff') format('woff')
}

@font-face {
    font-family: 'satoshi-bold';
    src: url('./assets/fonts/Satoshi-Bold.woff') format('woff')
}

@font-face {
    font-family: 'satoshi-regular';
    src: url('./assets/fonts/Satoshi-Regular.woff') format('woff')
}

@font-face {
    font-family: 'satoshi-light';
    src: url('./assets/fonts/Satoshi-Light.woff') format('woff')
}

@font-face {
    font-family: 'gellisto';
    src: url('./assets/fonts/Gellisto.woff') format('woff')
}

:root {
    --primary: #433E3C;
    --green: #C7BEBA;
    --secondary: #F0E7E4;
    --darkgrey: #F0E7E4;
    --lightbrown: #837b78;
    overflow-x: hidden;
}

::selection {
    background: var(--green);
    color: var(--primary);
}

html {
    scroll-behavior: smooth;
  }

body {
    background: var(--primary);
    color: var(--lightgrey);
    caret-color: var(--secondary);
    font-family: 'satoshi-regular', Arial, Helvetica, sans-serif;
}

input {
    outline: none;
}

h1 {
    font-family: 'gellisto';
    font-size: 86px;
}

h6 {
    font-family: 'gellisto';
    font-size: 24px;
}

p {
    font-size: 18px;
}

a:hover {
    color: var(--lightbrown);
}

.gellisto {
    font-family: 'gellisto';
}

.target {
    animation: rightToLeft 35s linear infinite;
}

.zoom:hover {
    transition: all 0.2s linear;
    transform: scale(1.005);
}

header nav {
    transition: 0.25s;
    transform: translateY(-100vh);
    z-index: 1000;
    background-color: var(--lightgrey);
}


header .responsive_nav {
    transform: none;
}

.open {
    transform: rotate(14deg);
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
}
